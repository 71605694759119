import React from 'react';
import { Link } from 'react-router-dom';
import NavBarPage from './NavBarPage';

class HeaderPage extends React.Component{
  
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      subtitle: props.subtitle,
      extra: props.extra,
      drizzle: props.drizzle,
      drizzleState: props.drizzleState,
      wallet: props.wallet
    }
    /**
     * <header className="header_ggdap">
        <span className="header_span">{this.state.title}</span>
      </header>
     */
  }

  renderConnectedWallet = () => {
    //console.log("wallet: " + this.state.wallet);
    if(this.state.wallet) {
      return <span className="wallet-span">{this.state.wallet}</span>
    }
    return "";
  }

  renderExtra() {
    if(this.state.extra && this.state.extra.length > 0) {
      return <span className="header_extra">{this.state.extra}</span>
    } 
    return null;
  }
  
  render() {
    return  (
     <div className='header'>
      <Link className="header_ggdap_logo" to="/"> <span className="beta">beta</span></Link>
      <header className="header_ggdap">
      {this.renderConnectedWallet()}
        <span className="header_span">{this.state.title}</span>
        {/*<span className="header_span_subtitle">{this.state.subtitle}</span>*/}
        {this.renderExtra()}
      </header>
      <NavBarPage/>
      </div>
    );
  }
}

export default HeaderPage;

