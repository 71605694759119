import React from 'react';
import { Link } from 'react-router-dom';
import HeaderPage from './HeaderPage'
import FooterPage from './FooterPage'

class StakeOptions extends React.Component{
  render() {
    return  (
      <div className="main">
        <HeaderPage title="Stake Options" subtitle="Stake Options"/>  
        <div className="box stake">
          <Link className="outerBox mainBox4" to="/stake/polygon/4">
            <div className="innerBox">
              <div className='box-frame-title'><span>Polygon Pioneers #2</span></div>
            </div>
          </Link>
          <Link className="outerBox mainBox4" to="/stake/arbitrum/3">
            <div className="innerBox">
              <div className='box-frame-title'><span>Arbitrum Pioneers #3</span></div>
            </div>
          </Link>
          <Link className="outerBox mainBox5" to="/gaming_pools">
            <div className="innerBox innerBoxDouble">
              <div className="box-frame-title">
                <div><span>Gaming Projects</span></div>
              </div>
            </div>
          </Link>
          <Link className="outerBox mainBox4" to="/stake/arbitrum/2">
            <div className="innerBox">
              <div className='box-frame-title'><span>Arbitrum Pioneers #2<br /><small>(Finished)</small></span></div>
            </div>
          </Link>
          <Link className="outerBox mainBox4" to="/stake/arbitrum/0">
            <div className="innerBox">
              <div className='box-frame-title'><span>Arbitrum Pioneers<br /><small>(Finished)</small></span></div>
            </div>
          </Link>
          <Link className="outerBox mainBox4" to="/stake/polygon/0">
            <div className="innerBox">
              <div className='box-frame-title'><span>Polygon Pioneers<br /><small>(Finished)</small></span></div>
            </div>
          </Link>
        </div>
        <FooterPage/>  
      </div>
    );
  }
}

export default StakeOptions;
