import React from 'react';
import HeaderPage from './HeaderPage'
import FooterPage from './FooterPage'

class TradeOptions extends React.Component{
  render() {
    return  (
      <div className="main">
      <HeaderPage title="Buy / Trade GGTK" subtitle="Trade Options"/>  
      <div className="box">
        <a rel="noreferrer" className='box-frame-link' target="_blank" href="https://app.uniswap.org/explore/pools/ethereum/0x4ac97Bcc1137bD1C39C1F34cfe5c250cc3CC7f59">
          <div className="outerBox mainBox6">
            <div className="innerBox">
              <div className='box-frame-title'><span>Uniswap (Ethereum)</span></div>
            </div>
          </div>
        </a>
        <a rel="noreferrer" className='box-frame-link' target="_blank" href="https://app.uniswap.org/explore/pools/polygon/0x2E97F2788CCbF06427d41bEA1fbFa5DC90386bEa">
          <div className="outerBox mainBox7">
              <div className="innerBox">
                <div className='box-frame-title'><span>Uniswap (Polygon)</span></div>
              </div>
        </div>
        </a>
      </div>
      <FooterPage/>  
      </div>
    );
  }
}

export default TradeOptions;
