import React from 'react';
import { withRouter, Link } from 'react-router-dom';

class FooterPage extends React.Component{
  
  constructor(props) {
    super(props);

    this.state = {
      title: props.title,
      subtitle: props.subtitle,
      drizzle: props.drizzle,
      drizzleState: props.drizzleState,
      hideFooter: props.hideFooter,
      version: ""
    }

    this.footerRef = React.createRef();
  }

  componentDidMount = async () => {
    let self = this;
    try {
      fetch('../../changelog.json', {
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         }
      }).then(function(response){
        return response.json();
      })
      .then(function(myJson) {
        self.setState({version: myJson.latest});
      });
    } catch (error) {
        console.log('error',error);
    }

    // Give React time to complete rendering
    setTimeout(this.alignFooterPos, 0);
    window.addEventListener("resize", this.alignFooterPos);
  }

  alignFooterPos = () => {
    const footerHeight = this.footerRef?.current ? this.footerRef.current.clientHeight : 0;
    const boxRef = document.getElementsByClassName("box")[0];
    if (boxRef) {
      boxRef.style.minHeight = `${window.innerHeight - footerHeight}px`;
    }
  };

  componentWillUpdate ({ location, history }) {
    if (location.pathname === this.props.location.pathname) {
        return;
    }

    if (history.action === 'PUSH') {
      this.alignFooterPos();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.alignFooterPos);
  }

  printExtraLink() {
    if(!this.state.hideFooter) {
     return <span><Link to="/changelog">{this.state.version} [ changelog ]</Link></span>
    }
    return null;
  }
  
  render() {
    return  (
      <footer className="footer_ggdap" ref={this.footerRef}>
        <div className="footer_container">
            <div className="contact_footer_column">
                <div className="footer_span">
                  <div className="logo"></div>
                </div>
                <div className="footer_span span_empty">&nbsp;</div>
                {/*<div className="footer_span">VAT-ID: 516179578</div>*/}
            </div>

            <div className="contact_footer_column">
                <div className="footer_span">Contact Us</div>
                <div className="footer_span"><a className="mail-link" href="mailto:info@ggdapp.com">info@ggdapp.com</a></div>
            </div>

            <div className="contact_footer_column">
                <div className="footer_span">Social networks</div>
                <div className="contact_footer_column_logos">
                  <a rel="noreferrer" target="_blank" href="https://t.me/ggdappchat"><div className="telegram"/></a>
                  <a rel="noreferrer" target="_blank" href="https://twitter.com/gg_dapp"><div className="twitter"/></a>
                  <a rel="noreferrer" target="_blank" href="https://ggdapp.medium.com/"><div className="medium"/></a>
                  <a rel="noreferrer" target="_blank" href="https://discord.gg/UAaqC4m5rK"><div className="discord"/></a>
                </div>
              
            </div>

        </div>
        <div className="footer_bottom">
         <span><a href="https://ggdapp.com/privacy-policy/" target='blank'>Privacy Policy</a></span><span><a href="https://ggdapp.com/terms" target='blank'>Terms of Use</a></span><span><a href="https://ggdapp.com/disclaimer" target='blank'>Disclaimer and User Warranties</a></span><span>All rights reserved. 2025</span>{/*this.printExtraLink()*/}
        </div>
      </footer>
    
    );
  }
}

export default withRouter(FooterPage);

